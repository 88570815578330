
// Vue reactivity
import { defineComponent, onMounted, reactive, ref } from 'vue';

// icons
import { close, filter, closeCircle, checkmark, downloadOutline, enterOutline, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonGrid, IonCol, IonRow, IonButtons, IonButton, IonIcon,
          IonList, IonItem, IonLabel, IonDatetime, IonDatetimeButton, IonSpinner, IonModal, IonNote,
          IonSelect, IonSelectOption, IonPopover, IonFab, IonFabButton,
          modalController, loadingController } from '@ionic/vue';

// composables
import { utils } from '@/composables/utils';
import { utilsDevice } from '@/composables/utilsDevice';
import { useI18n } from 'vue-i18n';

// services
import ReportService from '@/services/ReportService';

// data visualization
import { Grid, h, } from "gridjs";
import "gridjs/dist/theme/mermaid.css";

// csv export
import Papa from 'papaparse';

export default defineComponent({
  name: 'AICamReportModal',
  props: [
    "projectId",
    "project",
    "deviceAlerts",
  ],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonGrid, IonCol, IonRow, IonButtons, IonButton, IonIcon,
                IonList, IonItem, IonLabel, IonDatetime, IonDatetimeButton, IonSpinner, IonModal, IonNote,
                IonSelect, IonSelectOption, IonPopover, IonFab, IonFabButton,
                Grid, },
  setup(props) {
    const { t } = useI18n();

    const { downloadFile, closeModal, tStr, formatDate, getLocalizedStr, openScaffoldReportModal, uniqueId, openImageModal, getRelativeDate, } = utils();
    const { getRotateDegreeFromAcc, pointEntityTypes, getPointTypeObj, alertIcons, getDeviceLogMsg, formatVal, } = utilsDevice();
    const loadingData = ref(false);
    const filters = reactive({
      fromTs: null,
      toTs: null,

      // filter options
      type: 'all',
      level: 'all',
      availableTypes: ['震動警報', '傾斜警報', '暑熱警告'],
      availableLevels: ['low', 'medium', 'high', '1', '2', '3', 'green', 'yellow', 'red', 'black'],
      availableFloors: [],

      // selected filter
      location: "",
    });
    const popoverState = reactive({
      type: false,
      level: false,
    })
    const popoverEvent = ref();
    const setPopoverOpen = (popoverKey: any, state: boolean, ev: any) => {
      popoverEvent.value = ev; 
      popoverState[popoverKey] = state;
    };

    let grid;
    const gridDivId = `wrapper-${uniqueId()}`;
    const refreshData = () => {
      if (grid) grid.destroy(); // destroy for re-init

      const { fromTs, toTs, type, level, } = filters;
      loadingData.value = true;
    }

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      close, filter, closeCircle, checkmark, downloadOutline,

      // variables
      loadingData,
      filters,
      gridDivId,

      // methods
      t, tStr,
      openImageModal, getRelativeDate,
      closeModal, getLocalizedStr,
      fd: (d) => (formatDate(d, "YYYY-MM-DD HH:MM")),
      showFile: async (url: any) => {
        const loading = await loadingController.create({});
        await loading.present();
        await downloadFile(url);
        loading.dismiss();
      },

      getPointTypeObj,
      refreshData,

      // device logs
      alertIcons, getDeviceLogMsg,
      updateFilters: (key, val) => {
        filters[key] = val;
        refreshData();
      },

      // popover
      popoverState, popoverEvent,
      setPopoverOpen,

      // export CSV
      exportTableToCSV: () => {
        const { columns, data } = grid.config;
        const objects = data.map(arr => {
          const obj = {};
          arr.forEach((value, index) => {
            const key = columns[index];
            obj[key.name || key] = value;
          });
          return obj;
        });
        const csv = '\ufeff' + Papa.unparse(objects);
        const csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
        const csvURL = window.URL.createObjectURL(csvData);
        const tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', 'data.csv');
        tempLink.click();
      },

      allLocations: () => ([...new Set(props.deviceAlerts.map(a => a.location))]),
      filteredDeviceAlerts: () => {
        if (filters.location) return props.deviceAlerts.filter(a => a.location == filters.location);
        return props.deviceAlerts;
      },
    }
  }
});
