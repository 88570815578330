import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0fffee7a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "spin" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_grid, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_toolbar, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_buttons, { slot: "start" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_button, {
                      slot: "icon-only",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, { icon: _ctx.close }, null, 8, ["icon"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                (_ctx.project)
                  ? (_openBlock(), _createBlock(_component_ion_title, { key: 0 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createElementVNode("h2", null, _toDisplayString(_ctx.project.title), 1),
                            _createElementVNode("p", null, _toDisplayString(_ctx.tStr('違規截圖', 'Rulebreak Screenshots')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_ion_select, {
                  fill: "outline",
                  slot: "end",
                  modelValue: _ctx.filters.location,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filters.location) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_select_option, { value: "" }, {
                      default: _withCtx(() => [
                        _createTextVNode("All")
                      ]),
                      _: 1
                    }),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allLocations(), (l) => {
                      return (_openBlock(), _createBlock(_component_ion_select_option, { key: l }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(l), 1)
                        ]),
                        _: 2
                      }, 1024))
                    }), 128)),
                    _createTextVNode("> ")
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_grid, { style: {"padding-bottom":"100px"} }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", _hoisted_1, [
              _createVNode(_component_ion_spinner)
            ], 512), [
              [_vShow, _ctx.loadingData]
            ]),
            _createVNode(_component_ion_row, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredDeviceAlerts(), (alert, i) => {
                  return (_openBlock(), _createBlock(_component_ion_col, {
                    size: "4",
                    key: alert.id
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_card, { class: "ion-text-center" }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", {
                            class: "img-container",
                            onClick: ($event: any) => (_ctx.openImageModal(alert.imageLink, `${_ctx.getRelativeDate(alert.startAt)} - ${alert.event}`))
                          }, [
                            _createElementVNode("img", {
                              src: alert.imageLink
                            }, null, 8, _hoisted_3)
                          ], 8, _hoisted_2),
                          _createVNode(_component_ion_badge, {
                            color: "danger",
                            style: {"margin-top":"-12px","margin-bottom":"0","padding":"4px 8px","font-size":"11px"}
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(i+1) + ". " + _toDisplayString(alert.event) + " (" + _toDisplayString(_ctx.getRelativeDate(alert.startAt)) + ") ", 1)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_ion_badge, { style: {"padding":"4px 8px","font-size":"9px"} }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(alert.location), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}